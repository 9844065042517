var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Auth',[_c('div',{staticClass:"row justify-content-center my-5 my-lg-auto"},[_c('div',{staticClass:"col-sm-8 col-md-10 col-xl-8"},[_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h1',{staticClass:"display-3 mt-0 mb-3"},[_vm._v(_vm._s(_vm.$t("log-in")))]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-3"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":"","show":Boolean(_vm.error)}},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"admin-login"}},[_vm._v(_vm._s(_vm.$t("form.login-email")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && _vm.$v.form.email.$error },attrs:{"placeholder":_vm.$t('form.login-email-placeholder')},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.login-email-req")))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"admin-password"}},[_vm._v(_vm._s(_vm.$t("form.password")))]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                  },attrs:{"id":"admin-password","placeholder":_vm.$t('form.password-placeholder'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.password)?_vm._i(_vm.form.password,null)>-1:(_vm.form.password)},on:{"change":function($event){var $$a=_vm.form.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "password", $$c)}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                  },attrs:{"id":"admin-password","placeholder":_vm.$t('form.password-placeholder'),"type":"radio"},domProps:{"checked":_vm._q(_vm.form.password,null)},on:{"change":function($event){return _vm.$set(_vm.form, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                  },attrs:{"id":"admin-password","placeholder":_vm.$t('form.password-placeholder'),"type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showPassword)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.submitted && !_vm.$v.form.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.password-req"))+" ")]):_vm._e()])]),_c('div',{staticClass:"form-group mb-0 text-right form-note"},[_c('router-link',{attrs:{"to":"/forgot-password"}},[_vm._v(_vm._s(_vm.$t("form.forgot-password")))])],1)],1)]),_c('div',{staticClass:"mt-4"},[_c('button',{staticClass:"btn btn-primary btn-block w-100",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("log-in"))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }